.paymentOptions__content {
    position: relative;
    margin-top: 26px;
}

.paymentOptions__content h4{
    font: var(--font-large-bold);
    font-weight: 700;
    font-size: 20px;
}
