.duedate-calendar__header {
    width: 40rem;
}

.duedate-calendar__footer {
    padding: 1rem;
    width: 40rem;
    max-width: 100%;
    min-height: 8rem;
    background-color: #fff;
}

.duedate-calendar__footer button {
    min-width: 12rem;
    margin-right: 2rem;
}

.react-calendar {
    padding: 1rem;
    width: 40rem;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation button {
    color: #000000;
    min-width: 44px;
    background: none;
    font-size: 18px;
    margin-top: 8px;
}

.react-calendar__navigation__next-button {
    color: #A00808 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #fff;
}

.react-calendar__navigation button[disabled] {
    background-color: #fff;
    color: #fff;
}

abbr[title] {
    text-decoration: none !important;
    border-bottom: none !important;
}

.react-calendar__month-view__weekdays {
    color: #A00808;
    font-size: 15px;
    font-weight: 400;
}

.react-calendar__month-view__weekdays__weekday {
    text-decoration:dashed;
}

.react-calendar__tile--now {
    background: none;
}

.react-calendar__tile:disabled {
    background: none !important;
    color: #f1f3f6;

}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 6px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: none !important;
    border-radius: 6px;
    font-weight: bold;
    color: none !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}

.react-calendar__tile--active {
    background: #A00808 !important;
    border: 1px solid #A00808 !important;
    box-sizing: border-box;
    border-radius: 27px !important;
    color: #fff !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    color: none !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
}

.react-calendar__tile--range {
    background: none;
    color: #000000;
    border-radius: none;
    font-weight: 500 !important;
}

.react-calendar__tile--range:hover {
    color: #000000 !important;
}

.react-calendar__tile--originalDuedate {
    background: none;
    border: 1px solid #A00808 !important;
    box-sizing: border-box;
    border-radius: 27px !important;
    color: #000000 !important;
}

