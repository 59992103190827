.text-bold {
    font-weight: bold;
}

.text-aling-start {
    text-align: start;
}

.text-aling-end {
    text-align: end;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-14{
    font-size: 14px;
}

.font-size-16{
    font-size: 16px;
}

.font-size-20{
    font-size: 20px;
}

.color-white {
    color: #FFFFFF;
}

.color-dark-gray {
    color: #32373C;
}

.opacity-50 {
    opacity: 50%;
}

.padding4 {
    padding: 4px;
}

.padding-left-24 {
    padding-left: 24px;
}

.padding-right-24 {
    padding-right: 24px;
}

.padding-top-4 {
    padding-top: 4px;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-top-16 {
    padding-top: 16px;
}

.padding-top-34 {
    padding-top: 34px;
}

.padding-bottom-4 {
    padding-bottom: 4px;
}

.padding-bottom-8 {
    padding-bottom: 8px;
}

.padding-bottom-16 {
    padding-bottom: 16px;
}

.padding-bottom-34 {
    padding-bottom: 34px;
}

.width100 {
    width: 100%;
}

.inline {
    display:inline;
}

.bg-light-gray {
    background: #d3d3d3;
}

.bg-green {
    background: #3df803;
}

.bg-red {
    background: #f82803;
}

.bg-white {
    background: #FFFFFF;
}

.bg-tide {
    background: #BAB5AB;
}

.bg-darkest {
    background: #326B76;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-27 {
    margin-top: 27px;
}

.mt-75 {
    margin-top: 75px;
}

.mr-16 {
    margin-right: 16px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-175 {
    margin-bottom: 175px;
}

.mr-24 {
    margin-right: 24px;
}

.border-bottom-1 {
    border-bottom: 1px solid #A2BEC3;
}

.border-bottom-1-blk {
    border-bottom: 1px solid #000;
}
.border-top-1 {
    border-top: 1px solid #A2BEC3;
}

.border-top-4 {
    border-top: 4px solid #A2BEC3;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.invoice__container {
    /* background: #e9e9e9 !important; */
    /* position:relative; */
    top: 0%;
    left: 0%;
    bottom: 0%;
    right: 0%;
    height: 100vh;
    width: 100%;
}

.invoice__content {
    padding-left: 24px;
}

.Toastify__toast {
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 2px 15px rgba(0,0,0,0.2);
    min-height: 46px;
    border-radius: 12px;
    font-family: "Roboto", sans-serif, arial, verdana;
}

.Toastify__toast button {
    margin-left: 10px;
}

.Toastify__toast--error {
    background: #ec6838;
}

.Toastify__toast--warning {
    background: #FEFDEE;
    color: #bc7c0f;
}

.Toastify__toast--warning .Toastify__toast-body {
    text-shadow: none;
}

.Toastify__toast--info {
    background: #EBF1F2;
    color: #32373C;
}

.Toastify__toast-body {
    text-shadow: 0 1px 1px rgba(0,0,0,0.2);
}

.Toastify__progress-bar {
    visibility: hidden;
    height: 0;
    width: 0;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.align-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn,
a.btn,
.btn:focus {
    height: 42px;
    border-radius: 0 !important;
    transition: .2s ease-in-out;
    border: 0 !important;
    outline: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.btn-primary {
    background: #326B76;
}

.btn-primary-outline {
    background-color: transparent;
    border-color: transparent;
}

.form-control {
    background-color: #EAEBEC;
    border: 0;
    box-shadow: none;
    color:#000;
}
/* disable blue focus */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    box-shadow: none;
    outline: 0 none;
}

.input-group-addon {
    background-color: #EAEBEC;
    border-radius: 0;
    border: 0;
    color:#000;
}

/*correct color tone*/
.text-success {
    color: var(--color-green);
}

:root {
    --font-base: 'Roboto normal';
    --font-large-bold: 'Roboto Mono', monospace;

    --color-black: #0c0c0c;
    --color-gray: #545454;
    --color-white: #FFFFFF;
    --color-footer: #D1DFE1;
    --color-red: #A00808;
    --color-green: #23b316;
    --color-dark-gray: #32373C;
    --color-tide: #BAB5AB;
}

.success-table {
    border-top: 1px solid #79C653;
    border-bottom: 1px solid #79C653;
}

.success-table > tbody > tr > td {
    border: 0;
}

/** Accordion */
.panel-group .panel {
    border-radius: 0;
}

.panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.panel-default > .panel-heading {
    background-color: #fff;
    border-color: #fff;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #fff;
}

/** Full screen modal **/
.full_screen.modal-dialog {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    background: #EBF1F2;
}

.full_screen .modal-content {
    height: auto;
    height: 100%;
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    background-color: inherit;
}

.full_screen .modal-header {
    min-height: 50px;
    margin-bottom: 20px;
    border: none !important;
    padding: 0px;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.full_screen .modal-header .navbar-brand {
    margin-left: -15px;
}

.full_screen .modal-header .navbar-text {
    color: #fff;
    font-weight: bold;
    margin-left: 0;
}

.full_screen .modal-body {
    min-height: 50rem;
    padding: 0;
}

.full_screen .modal-header .close {
    font-size: 26px;
    padding-bottom: 0;
    color: #fff;
    opacity: 1;
    margin-top:10px;
    margin-right: -15px;
}

.full_screen .modal-footer {
    border-top: 0px;
}

@media screen and (max-width: 768px) {
    .full_screen .modal-header .close {
        margin-right: 0px;
    }

    .full_screen .modal-header .navbar-text {
        float: left;
    }
}

