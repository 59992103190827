.swish_modal.modal-dialog {
    background: #FFFFFF !important;
}

.flag-container .flag {
    margin-top: -5px;
}

.check-container .check {
    margin-top: -3px;
    margin-bottom: -3px;
}

.swish-icon {
    margin-top: -6px;
    margin-bottom: -6px;
}

.dropdown {
    position: relative;
}

.dropdown .button {
    border: none;
    padding: 6px;
    margin: -6px ;
}

.dropdown .button:hover {
    border: none;
    background: #D3D3D3;
}

.menu {
    position: absolute;
    z-index: 1;
    list-style-type: none;
    margin: 5px -5px 0;
    padding: 0;
    border: 1px solid grey;
}

.menu > li {
    margin: 0;
    padding-left: 2px;
    background: white;
}

.menu > li.bg-grey {
    margin: 0;
    background: #D3D3D3;
}

.menu > li:hover {
    background: #EAEBEC;
}

.menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    margin: 0;
}