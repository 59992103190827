.navbar-default {
    border: 0;
    border-bottom: 1px;
    border-radius: 0;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.navbar-default .navbar-link {
    color: #fff;
}

.navbar-default .navbar-link:hover {
    color: #fff;
}

.navbar-default .navbar-header {
    float: left;
}

.navbar-default .navbar-collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
    visibility: visible !important;
}

.navbar-default .navbar-right {
    float: right !important;
}

.navbar-default .navbar-help {
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 0;
}